

.header_container {
    position: fixed;
    width: 100%;
    height: 70px;
    /* background-color: #121319; */
    z-index: 2;
    /* border-bottom: 1px solid #ccc; */
    /* background-color: red; */
}

.header_container_scrolled {
    position: fixed;
    background-color: var(--trans_gray);
    background-color: var(--trans_gray_dark);
    backdrop-filter: blur(40px);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
}

.header_margin {
    padding-top: 80px;
}

.header_logo {
    position: absolute;
    left: 0px;
    top: 10px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.header_text_logo {
    position: absolute;
    left: 65px;
    top: 10px;
    line-height: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
}

.header_language_selector {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 93px;
    height: 48px;
    border: 1px solid var(--light_gray);
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    
}

.header_language_label {
    position: absolute;
    left: 50px;
    line-height: 48px;
    font-weight: bold;
    color: white;
    font-family: "Unbounded", sans-serif;
}

.header_flag_icon {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 40px;
    height: 40px;
}

.header_wallet {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 140px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    border: 1px solid var(--light_gray);
    font-weight: bold;
    color: white;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}

.header_buy_now {
    overflow: hidden;
    position: absolute;
    right: 152px;
    top: 10px;
    width: 140px;
    height: 50px;
    text-align: center;
    color: white;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_blue);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.large_text_big {
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.inline_iframe_logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
}

.jetpaw_iframe_header {
    position: fixed;
    text-align: center;
    z-index: 100000000000000;
    top: 15px;
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

@media (max-width: 520px) {

    .header_text_logo {
        font-size: 22px;
    }

    .header_container {
        height: 70px;
        /* background-color: var(--trans_gray_dark);
        backdrop-filter: blur(40px);
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.15); */
    }

    .header_language_selector {
        top: 69px;
    }

    .header_buy_now {
        top: 10px;
        right: 90px;
        width: 80px;
    }

    .header_wallet {
        right: 0px;
        top: 10px;
        width: 80px;
    }

    .header_container_scrolled {
        height: 70px;
    }

    /* .header_container_scrolled .header_buy_now {
        top: 10px;
        left: 60px;
        width: calc(100% - 220px);
    } */

    /* .header_container_scrolled .header_language_selector {
        top: 10px;
        width: 48px;
    } */

    /* .header_container_scrolled .header_wallet {
        top: 10px;
        right: 60px;
        width: 90px;
    }

    .header_container_scrolled .header_language_label {
        display: none;
    } */
}

/* .hide_title_text {
    display: none;
}

@media (min-width: 520px) {
    .hide_title_text {
        display: block;
    }
} */
