
.purchase_funnel_container {
    margin-left: 20px;
    width: calc(100% - 40px);
    /* overflow: scroll; */
    /* min-height: 410px;
    max-height: calc(100% - 80px); */
    padding-bottom: 20px;
    scrollbar-width: none;
}

.purchase_funnel_container::-webkit-scrollbar {
    display: none;
}

.tab_selector {
    width: 100%;
    height: 50px;
}

.tab_inner {
    display: inline-block;
    width: 50%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-bottom: 1px solid gray;
    opacity: 0.5;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab_inner.large {
    font-size: 22px;
    font-family: "Unbounded", sans-serif;
}

.tab_selected {
    border-bottom: 2px solid var(--primary_blue);
    color: white;
    opacity: 1;
}

.tab_selected.large {
    font-weight: bold;
}


.payment_input_container_short {
    position: relative;
    width: 215px;
    height: 58px;
    border-radius: 10px;
    border: 2px solid var(--dark_gray);
    border: 2px solid var(--trans_gray);
    transition: width 0.3s ease-in-out;
    /* opacity: 0.5; */
}

.payment_input_container {
    transition: width 0.3s ease-in-out;
}

.paste_input_button {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 90px;
    height: 40px;
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
}

.disconnect_input_button {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 130px;
    height: 40px;
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
}

.paste_text_inner {
    position: absolute;
    right: 10px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    width: calc(100% - 48px);
}

.paste_icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
}

.wallet_address_input {
    all: unset;
    position: absolute;
    top: 4px;
    left: 90px;
    height: 50px;
    width: calc(100% - 200px);
    background-color: transparent;
    border: none;
    font-size: 18px;
}

.wallet_address_input:focus {
    outline: none;
}

.long_input_w_paste {
    all: unset;
    position: absolute;
    top: 4px;
    left: 65px;
    height: 50px;
    width: calc(100% - 170px);
    background-color: transparent;
    border: none;
    font-size: 18px;
}

.long_input_w_paste:focus {
    outline: none;
}

.full_width_input {
    all: unset;
    position: absolute;
    top: 4px;
    left: 90px;
    height: 50px;
    width: calc(100% - 100px);
    background-color: transparent;
    border: none;
    font-size: 18px;
}

.full_width_input:focus {
    outline: none;
}

.value_indicator {
    position: absolute;
    left: 65px;
    line-height: 58px;
    width: calc(100% - 110px);
    font-size: 18px;
    text-align: center;
}

.wallet_indicator {
    position: absolute;
    left: 65px;
    line-height: 58px;
    width: calc(100% - 215px);
    font-size: 18px;
    text-align: center;
    overflow: scroll;
    scrollbar-width: none;
}

.wallet_indicator::-webkit-scrollbar {
    display: none;
}

.payment_input_currency_selector_short {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 50px;
    height: 50px;
    background-color: var(--dark_gray);
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
    
}

.payment_edit_icon {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 30px;
    height: 30px;
    /* background-color: var(--trans_gray);
    border-radius: 5px; */
}

.title_container {
    position: relative;
}

.back_icon {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
}

.help_icon {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
}

.payment_button.unselectable {
    opacity: 0.25;
    pointer-events: none;
}