.history_list_item {
    position: relative;
    width: 100%;
    height: 75px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--trans_gray);
}

.history_icon {
    position: absolute;
    left: 10px;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
}

.extra_list_label {
    position: absolute;
    left: 60px;
    bottom: 15px;
    font-size: 14px;
}

.list_label_right {
    position: absolute;
    right: 15px;
    top: 10px;
    line-height: 30px;
    height: 30px;
}

.extra_list_label_right {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 14px;
    color: var(--light_gray);
}

