* {
    margin: 0;
    padding: 0;
    /* font-family: "Roboto Mono", monospace; */
    font-family: "Poppins", sans-serif;
}

html, body {
    height:100%;
    width: 100%;
    font-size: 18px;
    background-color: var(--background_color);
    color: white;
}

input {
    font-size: 18px;
}

li {
    margin-left: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no_events {
    pointer-events: none;
}


.yellow_glow {
    /* border: 1px solid var(--primary_yellow); */
    box-shadow: 0px 0 1px 1px var(--primary_yellow); /*rgba(255, 236, 113, 0.95);*/
}

.blue_glow {
    /* border: 1px solid var(--primary_yellow); */
    box-shadow: 0px 0 1px 1px var(--primary_blue); /*rgba(255, 236, 113, 0.95);*/
}

.black_glow {
    box-shadow: 0px 0 1px 1px var(--dark_black);
}


.white_glow {
    box-shadow: 0px 0 1px 1px rgba(255, 255, 255, 0.5);
}

.inquiry_message {
    width: calc(100% - 24px);
    height: 200px;
    padding: 10px;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid var(--trans_gray);
    color: white;
    resize: none;
    font-size: 18px;
}

.inquiry_message:focus {
    border: 2px solid var(--primary_blue);
    outline: none;
}

@keyframes pulse_yellow_glow {
    0%, 100% {
        box-shadow: 0 0 5px 1px rgba(255, 236, 113, 0.6);
    }
    50% {
        box-shadow: 0 0 5px 2px rgba(255, 236, 113, 0.75);
    }
}


.only_mobile {
    display: none;
}

@media (max-width: 850px) {
    .only_mobile {
        display: block;
    }

    .only_desktop {
        display: none;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}