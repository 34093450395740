
.switch_container {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 60px;
    height: 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.switch_container.off {
    background-color: var(--light_gray);
}

.switch_container.on {
    background-color: var(--green_text);
}

.switch_button {
    position: absolute;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: white;
    transition: right 0.3s ease;
}

.switch_button.off {
    right: 5px;
}

.switch_button.on {
    right: calc(100% - 25px);
}