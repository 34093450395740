

.title_relative_container {
    position: relative;
    line-height: 50px;
}

.right_align_button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 150px;
    height: 50px;
    line-height: 50px;
    background-color: var(--primary_yellow);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    border-radius: 10px;
    text-align: center;
    color: black;
}

.search_input_container {
    position: relative;
    width: 100%;
    height: 58px;
    border-radius: 10px;
    border: 2px solid var(--dark_gray);
    border: 2px solid var(--trans_gray);
}

.search_input {
    all: unset;
    position: absolute;
    top: 4px;
    left: 90px;
    height: 50px;
    width: calc(100% - 100px);
    background-color: transparent;
    border: none;
    font-size: 18px;
    text-align: left;
}

.friend_list_item {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--trans_gray);
}

.quizz_list_item {
    position: relative;
    width: 100%;
    min-height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--trans_gray);
}

.accept_button {
    position: absolute;
    right: 50px;
    top: 10px;
    width: 30px;
    line-height: 30px;
    height: 30px;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
}

.decline_button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    line-height: 30px;
    height: 30px;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
}

.inner_icon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
}

.stake_list_item {
    position: relative;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--trans_gray);
}

.stake_countdown {
    position: absolute;
    left: 60px;
    bottom: 10px;
    line-height: 30px;
    font-size: 14px;
}

.claim_all_button {
    position: relative;
    width: 100%;
    max-width: 200px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--primary_blue);
    text-align: center;
    line-height: 50px;
    color: black;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.stake_icon {
    position: absolute;
    left: 10px;
    top: 25px;
    width: 30px;
    height: 30px;
}

.right_icon_stake {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 20px;
    height: 20px;
}