

.black_full_background {
    width: 100%;
    background-color: var(--dark_black);
}

.one_third_section {
    vertical-align: top;
    display: inline-block;
    width: calc(33.33% - 20px);
    margin-right: 20px;
}

.footer_logo {
    vertical-align: top;
    display: inline-block;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.footer_text_logo {
    vertical-align: top;
    display: inline-block;
    margin-left: 20px;
    line-height: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
}

.bubble_text {
    display: inline-block;
    background-color: var(--blue_gray);
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
}

.footer_text_container {
    position: relative;
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
}

.footer_icon {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    /* background-color: blue; */
}


@media (max-width: 850px) {

    .one_third_section {
        display: block;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 40px;
    }

    .one_third_section:nth-child(5) {
        margin-bottom: -40px;
    }
}