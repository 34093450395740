
.why_component {
    position: relative;
    display: inline-block;
    width: calc(25% - 20px);
    padding: 10px;
    text-align: center;
    vertical-align: top;
}

.lb_image_container {
    position: relative;
    position: relative;
    width: 76px;
    height: 76px;
    border-radius: 10px;
    background-color: black;
    border: 2px solid var(--dark_gray);
}

.why_image_container {
    position: relative;
    margin-left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: black;
}

.why_image {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
}

.why_text {

}

.center_desktop {
    text-align: center;
}

.slight_line {
    width: 100%;
    height: 1px;
    background-color: var(--dark_gray);
}

.right_forced_button {
    position: absolute;
    right: 0px;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
}

.unavailable_overlay {
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--trans_gray_dark);
    text-align: center;
}

.padded_unavail {
    position: absolute;
    /* height: 30px;
    line-height: 30px; */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-25%);
    border-radius: 10px;
    top: calc(50% - 15px);
    background-color: black;
    
}

.extra_container {
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}
  
.extra_container.show {
    opacity: 1;
    height: auto;
}

@media (max-width: 850px) {

    .why_component {
        display: block;
        width: 100%;
        padding: 0px;
        text-align: left;
    }

    .why_image_container {
        display: inline-block;
        margin-left: 0px;
    }

    .why_text {
        vertical-align: top;
        display: inline-block;
        margin-left: 20px;
        width: calc(100% - 100px);
    }

    .center_desktop {
        text-align: left;
    }
}


.bullet_special_component {
    width: 100%;
}

.bullet_image_container {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: black;
}

.bullet_text {
    vertical-align: top;
    display: inline-block;
    margin-left: 20px;
    width: calc(100% - 100px);
}

.staking_sample_chart {
    width: 100%;
    border-radius: 10px;
}

.unstake_anytime {
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
}

.read_wp_button {
    display: inline-block;
    margin-right: 20px;
    width: 250px;
    height: 50px;
    text-align: center;
    color: white;
    line-height: 50px;
    border-radius: 10px;
    background-color: black;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
    border: 1px solid gray;
}