
.seed_phrase_container {
    width: 100%;
}

.seed_phrase_item {
    position: relative;
    display: inline-block;
    width: calc(33.33% - 6.667px);
    height: 50px;
    background-color: var(--trans_gray);
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 10px;
}

.seed_phrase_item.no_margin {
    margin-right: 0px;
}

.seed_phrase_item.dashed {
    height: 48px;
    width: calc(33.33% - 8.667px);
    border: 1px dashed var(--light_gray);
    background-color: transparent;
}

.seed_phrase_item.unselectable {
    opacity: 0.25;
}

.seed_phrase_item.selectable {
    background-color: var(--blue_gray);
}

.seed_number_indicator {
    position: absolute;
    top: 5px;
    left: 10px;
    line-height: 40px;
    color: var(--light_gray);
    font-size: 14px;
}

.seed_word_indicator {
    position: absolute;
    top: 5px;
    left: 35px;
    line-height: 40px;
    width: calc(100% - 45px);
    color: white;
}

.seed_word_indicator.selectable {
    left: 10px;
    width: calc(100% - 20px);
    text-align: center;
}

@media (max-width: 520px) {
    .seed_number_indicator {
        left: 8px;
        font-size: 12px;
    }

    .seed_word_indicator {
        font-size: 14px;
        left: 30px;
        width: calc(100% - 40px);
    }
}