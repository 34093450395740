.news_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    grid-auto-rows: masonry;
    grid-auto-flow: dense;
}
  
.news_item_container {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--trans_gray);
    display: flex;
    flex-direction: column;
}

.news_image_container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
}
  
.news_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: grayscale(100%); */
}

.news_color_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* mix-blend-mode: screen; */
    mix-blend-mode: color;
    background-color: var(--primary_yellow);
    opacity: 0.5;
}

.news_image_main {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.news_text_container {
    padding: 10px;
}

.two_lines_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.three_lines_text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.horizontal_ticker_container {
    height: 150px;
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
}

.horizontal_ticker_container::-webkit-scrollbar {
    display: none;
}

.horitzontal_ticker_inner {
    width: 2090px;
    height: 100%;
    height: 150px;
    overflow: hidden;
}

.ticker_container {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 150px;
    margin-right: 10px;
    background-color: var(--trans_gray);
    border-radius: 10px;
}

.ticker_item_container {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.ticker_text_indicator {
    position: absolute;
    top: 70px;
    left: 10px;
    width: calc(100% - 20px);
    font-size: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ticker_percentage_change_indicator {
    position: absolute;
    top: 100px;
    left: 10px;
    font-size: 32px;
    width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ticker_container:last-child {
    margin-right: 0px;
}

.small_spacer {
    height: 10px;
}

.crypto_top_container {
    position: relative;
    height: 70px;
}

.crypto_top_icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 70px;
    border-radius: 70px;
}

.crypto_top_price {
    position: absolute;
    top: 0px;
    left: 80px;
    line-height: 40px;
}

.crypto_change_price {
    position: absolute;
    top: 30px;
    left: 80px;
}