
.lined_background_blue {
    /* background-color: black; */
    position: relative;
    background-image: 
        linear-gradient(to bottom, var(--blue_gray) 1px, transparent 1px),
        linear-gradient(to right, var(--blue_gray) 1px, transparent 1px),
        linear-gradient(-45deg, transparent calc(50% - 0.5px),  var(--blue_gray) 1px, transparent calc(50% + 0.5px))
        ;
    background-size: 60px 60px;
}

.feature_image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.fade_out_black {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, transparent, transparent 65%, var(--dark_black) 90%);
}

.fade_out_gray {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, transparent, transparent 65%, rgba(80, 80, 80, 0.25) 90%);
}

.blue_background {
    background-color: var(--primary_blue);
    color: white;
}