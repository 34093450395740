/*

.test {
    position: absolute;
    top: -100px;
    height: 100px;
    width: 100px;
    background-color:  green;
} */


.stick_text {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: var(--background_color);
}

.small_pop_up_container {
    position: absolute;
    right: 20px;
    width: 300px;
    bottom: 20px;
    background-color: var(--background_color);
    border-radius: 10px;
    transition: transform 0.5s ease-out;
    animation: slide_up_small 0.5s ease-out forwards;
}

.small_pop_up_container.hide {
    animation: slide_down_small 0.25s ease-out forwards;
}

.language_selector_container {
    position: absolute;
    left: calc(50% - 250px);
    top: 100%;
    transform: translateY(0);
    width: 500px;
    background-color: var(--background_color);
    border-radius: 10px;
    transition: transform 0.5s ease-out;
    animation: slide_up 0.5s ease-out forwards;
    max-height: calc(100% - 100px);
    overflow: scroll;
    scrollbar-width: none;
}

.language_selector_container::-webkit-scrollbar {
    display: none;
}

.language_selector_container.hide {
    animation: slide_down 0.25s ease-out forwards;
}

.language_list_container {
    position: relative;
    margin-left: 20px;
    width: calc(100% - 40px);
    /* height: 410px; */
    /* overflow: scroll;
    scrollbar-width: none; */
}

.lootbox_list_container {
    position: relative;
    width: 100%;
}

/* .language_list_container::-webkit-scrollbar {
    display: none;
} */

.language_item {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--trans_gray);
}

.language_icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.language_amount_label {
    position: absolute;
    left: 60px;
    top: 10px;
    line-height: 30px;
    height: 30px;
}

.language_arrow_right {
    position: absolute;
    right: 10px;
    top: 17.5px;
    width: 15px;
    height: 15px;
}

@media (max-width: 520px) {

    .small_pop_up_container {
        width: calc(100% - 40px);
    }

    .language_selector_container {
        top: auto;
        left: 2px;
        width: calc(100% - 4px);
        bottom: 0px;
        border-radius: 10px 10px 0 0;
    }

    .language_selector_container {
        position: fixed;
        right: 0;
        bottom: -100%;
        left: 2px;
        width: calc(100% - 4px);
        height: auto;
        border-radius: 10px 10px 0 0;
        animation: slide_up_mobile 0.5s ease-out forwards;
    }

    .language_selector_container.hide {
        animation: slide_down_mobile 0.5s ease-out forwards;
    }

    @keyframes slide_up_mobile {
        from {
            bottom: -100%;
        }
        to {
            bottom: 0;
        }
    }

    @keyframes slide_down_mobile {
        from {
            bottom: 0;
        }
        to {
            bottom: -100%;
        }
    }
}

@keyframes slide_up {
    0% {
        top: 100%;
        transform: translateY(100%);
    }
    100% {
        top: 50%;
        transform: translateY(-50%);
    }
}

@keyframes slide_down {
    from {
        top: 50%;
        transform: translateY(-50%);
    }
    to {
        top: 100%;
        transform: translateY(0);
    }
}

@keyframes slide_up_small {
    0% {
        bottom: -300px;
    }
    100% {
        bottom: 20px;
    }
}

@keyframes slide_down_small {
    0% {
        bottom: 20px;
    }
    100% {
        bottom: -300px;
    }
}