

.radial_glow_extra_coin {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background: radial-gradient(circle, rgba(0,181,250,0.2), transparent 70%);
    border-radius: 50%;
    width: 250px;
    height: 250px;
    /* background-color: red; */
}

.earn_item_container {
    margin-top: 20px;
    position: relative;
    display: inline-block;
    width: calc(25% - 15px);
    margin-right: 20px;
    background-color: var(--dark_black);
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 50px 100%, 0 calc(100% - 50px));
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

.earn_inner_hide {
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: var(--dark_black);
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 50px 100%, 0 calc(100% - 50px));
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    z-index: -1;
}

.no_right_margin {
    margin-right: 0px;
}

.clip_top_right {
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 0 100%);
}

.clip_bottom_left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50px 100%, 0 calc(100% - 50px));
}

/* .earn_item_container:nth-child(2n+1) {
    margin-right: 0px;
} */

.earn_icon_container:nth-child(2) {
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 50px 100%, 0 calc(100% - 50px));
}

.earn_icon_container {
    margin-left: calc(50% - 40px);
    margin-top: 40px;
    width: 80px;
    height: 80px;
    /* background-color: var(--dark_gray); */
    border-radius: 10px;
}

.center_text_container {
    margin-left: calc(50% - 250px);
    width: 500px;
    text-align: center;
}


.token_ill_container {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    /* background-color: var(--dark_gray); */
    /* background-color: var(--background_color); */
    border-radius: 10px;
    overflow: hidden;
}

.radial_mask {

    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;

    mask-image: radial-gradient(circle, black 50%, transparent 80%);
    -webkit-mask-image: radial-gradient(circle, black 50%, transparent 80%);
    mask-size: 100% 100%;
    mask-position: center;

}

.full_inner {
    position: relative;
    padding: 20px;
    width: calc(100% - 40px);
    /* background-image: radial-gradient(circle, transparent, transparent 20%, var(--dark_gray) 100%); */
}

.token_ill_inner_container {
    position: relative;
    width: 100%;
    perspective: 1000px; 
}

.tokens_container {
    position: relative;
    animation: token_motion 3s linear infinite;
}

.token_image {
    position: relative;
    margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: calc(100% - 100px);
}

.token_overlay_image {
    position: absolute;
    top: 50px;
    left: 50px;
    width: calc(100% - 100px);
    animation: token_pulse 3s linear infinite;
}

.token_overlay_image_flowing {
    display: block;
    opacity: 0.8;
    /* -webkit-mask-image: linear-gradient(to right, var(--trans_gray_med), var(--primary_yellow), var(--trans_gray_med)); */
    mask-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0.7), rgba(255,255,255,1));
    /* -webkit-mask-size: 10% 200%; */
    mask-size: 10% 100%;
    animation: sliding_mask 3s linear infinite delay 3s;
    filter: brightness(1.2);
}

@keyframes token_pulse {
    0%, 100% {
        opacity: 0;
        filter: brightness(1);
    }
    50% {
        opacity: 1;
        filter: brightness(1.3) blur(10px);
    }
}

@keyframes token_motion {
    0%, 100% {
        transform: translateX(0) translateY(0);
    }
    50% {
        transform: translateX(1.5%) translateY(-3%) rotate(-2deg);
    }
}


.step_container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    min-height: 30px;
    position: relative;
}

.step_indicator_container {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: var(--primary_yellow);
    color: var(--dark_black);
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}

.step_indicator_container.extra {
    background-color: #f589ff;
    /* background-color: rgb(231, 100, 255); */
}

.step_text_container {
    position: relative;
    top: -3px;
    margin-left: 50px;
    width: calc(100% - 50px);
    font-size: 24px;

}

.extra_connector {
    position: relative;
    width: 100%;
    height: 1px;
    z-index: 1;
}


.horitzontal_line {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: var(--background_color);
}

.left_box_decor {
    position: absolute;
    top: -5px;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: var(--background_color);
}

.right_box_decor {
    position: absolute;
    top: -5px;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: var(--background_color);
}

.center_box_decor {
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: var(--background_color);
}


.earn_description_container {
    position: relative;
    display: inline-block;
    padding: 40px;
    width: calc(50% - 80px);
    vertical-align: top;
    float: right;
}

.earn_container {
    position: relative;
    display: inline-block;
    padding: 40px;
    width: calc(50% - 80px);
    vertical-align: top;
    pointer-events: none;
    /* float: right; */
}

.full_video {
    position: relative;
    margin-left: 10px;
    margin-top: 10px;
    width: calc(100% - 20px);
}

.full_image {
    position: relative;
    width: 100%;
    max-height: 450px;
    object-fit: contain;
    opacity: 0.8;
    -webkit-mask-image: linear-gradient(to top, transparent, 15%, var(--background_color) 30%);
    mask-image: linear-gradient(to top, transparent, 15%, var(--background_color) 30%);
}

@media (max-width: 850px) {
    .earn_description_container {
        padding: 40px 20px;
        display: block;
        width: calc(100% - 40px);
    }

    .earn_container {
        padding: 40px 20px;
        display: block;
        width: calc(100% - 40px);
    }
}