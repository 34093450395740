
.feature_container_0 {
    display: inline-block;
    width: calc(50% - 10px);
    height: 500px;
    background-color: var(--blue_background);
    border-radius: 10px;
}

.lined_background {
    position: relative;
    /* background-color: black; */
    left: -2px;
    background-image: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
        linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
        linear-gradient(-45deg, transparent calc(50% - 0.5px), rgba(255, 255, 255, 0.2) 1px, transparent calc(50% + 0.5px))
        ;
    background-size: 60px 60px;
}


.lined_background_light {
    position: relative;
    /* background-color: black; */
    left: -2px;
    background-image: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
        linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
        linear-gradient(-45deg, transparent calc(50% - 0.5px), rgba(255, 255, 255, 0.1) 1px, transparent calc(50% + 0.5px))
        ;
    background-size: 60px 60px;
}


/* .lined_background {
    background-color: black;
    background: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
        linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
    background-size: 60px 60px;
}

.diagonal_background {
    background: 
        linear-gradient(-45deg, transparent 24.5%, #282a36 25%, #282a36 25.5%, transparent 26%, transparent 74%, #282a36 74.5%, #282a36 75%, transparent 75.5%);
    background-size: 84.85px 84.85px;
    background-position: 0 0, 30px 30px;
    color: #fff;
} */

.features_container {
    width: 100%;
    height: 570px;
    overflow: scroll;
    /* display: flex;
    overflow-x: auto;
    white-space: nowrap;  */
    scrollbar-width: none;
}

.features_outer {
    width: 100%;
    height: 570px;
    bottom: 0px;
    position: absolute;
}

.zero_opacity {
    opacity: 0;
}

.features_item_unselected {
    transform: scale(90%);
    opacity: 0.8;
}

.features_item_unselected_far {
    transform: scale(80%);
    opacity: 0.5;
}

.fade_item_left {
    -webkit-mask-image: linear-gradient(to left, 
        black 0%, 
        black 10%, 
        transparent 40%, 
        transparent 100%);
    mask-image: linear-gradient(to left, 
        black 0%, 
        black 10%, 
        transparent 40%, 
        transparent 100%);
}

.fade_item_right {
    -webkit-mask-image: linear-gradient(to right, 
        black 0%, 
        black 10%, 
        transparent 40%, 
        transparent 100%);
    mask-image: linear-gradient(to right, 
        black 0%, 
        black 10%, 
        transparent 40%, 
        transparent 100%);
}

.features_container::-webkit-scrollbar {
    display: none;
}

.features_container_inner {
    margin-top: 1px;
    width: 1160px;
    height: 500px;
    

    /* -webkit-mask-image: linear-gradient(to right, transparent, black 200px, black calc(100% - 200px), transparent);
    mask-image: linear-gradient(to right, transparent, black 200px, black calc(100% - 200px), transparent); */
}

.features_item {
    position: relative;
    display: inline-block;
    width: 275px;
    height: 495px;
    margin-right: 20px;
    /* background-color: black; */

    background-color: rgba(80, 80, 80, 0.25);
    /* background-color: transparent; */
    backdrop-filter: blur(1px);

    border-radius: 10px;
    cursor: pointer;

    transition: transform 0.3s ease-in-out;
}

.features_item:last-child {
    margin-right: 0px;
}

.featured_item_icon_holder {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: white;
    border-radius: 5px;
    color: black;
    font-size: 30px;
}

.features_item_title {
    position: absolute;
    top: 12px;
    left: 20px;
    width: calc(100% - 60px);
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size: 24px;
    font-weight: bold;
}

.feature_main_image {
    position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;
    height: 275px;
}

.overlay_hooks_container {
    position: absolute;
    top: 80px;
    left: calc(50% - 117.5px);
    width: 235px;
    height: 235px;
}

.overlay_buy_button {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 117.5px);
    text-align: center;
    line-height: 50px;
    color: black;
    width: 235px;
    height: 50px;
    background-color: var(--primary_yellow);
    border-radius: 10px;
}

.overlay_left_button {
    position: absolute;
    left: 10px;
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background-color: #43434a;
    background-color: var(--primary_yellow);
    border-radius: 10px;
}

.overlay_right_button {
    position: absolute;
    right: 10px;
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background-color: #43434a;
    background-color: var(--primary_yellow);
    border-radius: 10px;
}

.inner_arrow_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
}

.feature_video {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 275px;
    height: 275px;
    border: none;
    /* border-radius: 10px; */
    object-fit: cover;
    overflow: hidden;
}

.hook_left_blue {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    background-color: var(--primary_blue);
    clip-path: polygon(0 25%, 25% 0, 100% 0, 100% 30%, 30% 30%, 30% 100%, 0 100%);
}

.hook_left_white {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    background-color: var(--light_gray);
    clip-path: polygon(0 25%, 25% 0, 100% 0, 100% 30%, 30% 30%, 30% 100%, 0 100%);
}

.hook_left_light {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    background-color: #282a30;
    clip-path: polygon(0 10%, 10% 0, 100% 0, 100% 10%, 10% 10%, 10% 100%, 0 100%);
}

.hook_left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    background-color: var(--primary_yellow);
    clip-path: polygon(0 25%, 25% 0, 100% 0, 100% 30%, 30% 30%, 30% 100%, 0 100%);
}

.hook_transform_top_right {
    top: 0px;
    left: auto;
    right: 0px;
    transform: scaleX(-1);
}

.hook_transform_bottom_right {
    top: auto;
    left: auto;
    bottom: 0px;
    right: 0px;
    transform: rotate(-180deg);
}

.hook_transform_bottom_left {
    top: auto;
    bottom: 0px;
    transform: rotate(-180deg) scaleX(-1);
}

.feature_text_item_container {
    position: absolute;
    top: 335px;
    left: 10px;
    width: calc(100% - 20px);
}

.feature_text_item {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--background_color);
    border-radius: 10px;
}

.feature_text_item_price {
    margin-top: 10px;
    width: 100%;
    height: 90px;
    text-align: center;
    background-color: var(--background_color);
    border-radius: 10px;
}

.price_text {
    padding-top: 17px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.price_text_strike {
    color: var(--orange_text)
}

.price_text_discount {
    font-weight: bold;
    color: var(--green_text)
}

.fade_grid {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, transparent, var(--background_color));
}

.button_deselected {
    opacity: 0.25;
}


@media (max-width: 850px) {

    .features_container {
        margin-left: -10px;
        width: calc(100% + 20px);
    }

    .overlay_left_button {
        left: calc(50% - 117.5px - 45px);
        top: 172.5px;
    }
    
    .overlay_right_button {
        right: calc(50% - 117.5px - 45px);
        top: 172.5px;
    }

/* 
    .features_container_inner {
        pointer-events: none;
    } */
}

.spinning_overlay {
    animation: overlay_spin 5s linear infinite;
    filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.6));
    transform-style: preserve-3d;
}

.spinning_overlay_w_z {
    animation: overlay_spin_w_z 5s linear infinite;
    filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.6));
    transform-style: preserve-3d;
}

.shine_overlay {
    animation: shine_animation 2s linear infinite;
}

.rocket_overlay_spin {
    transform: scale(70%);
    animation: full_rotation 10s linear infinite;
}

.presale_tracking_animated {
    position: absolute;
    width: 100%;
    bottom: 35px;
    text-align: center;
    font-size: 12px;
    color: rgb(0, 229, 255);
    font-family: "Roboto Mono", monospace;
    animation: flash_text 1s linear infinite;
}

.presale_tracking_animated.top {
    bottom: 18px;
}

.slight_rotation {
    animation: slight_rotation 5s linear infinite;
    filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.6));
}

.forced_feature_crop {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.floating_coin_overlay {
    filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.6));
    animation: slight_motion 5s linear infinite;
}

@keyframes slight_motion {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
}

@keyframes slight_rotation {
    0%, 100% {
        top: 100px;
        left: 50px;
        transform: scale(80%) rotate(-15deg);
    }
    50% {
        top: 100px;
        left: 50px;
        transform: scale(80%) rotate(-5deg);
    }
}

@keyframes flash_text {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
}

@keyframes full_rotation {
    0% {
        top: -20px;
        transform: scale(70%) rotate(0deg);
    }
    100% {
        top: -20px;
        transform: scale(70%) rotate(360deg);
    }
}

@keyframes shine_animation {
    0%, 100% {
        filter: 
        drop-shadow(-1px -2px 5px rgba(0, 200, 255, 0))
        drop-shadow(-1px 2px 5px rgba(0, 200, 255, 0))
        ;
    }
    50% {
        filter:
        drop-shadow(-5px -10px 5px rgba(0, 200, 255, 0.6))
        drop-shadow(-5px 10px 5px rgba(0, 200, 255, 0.6))
        ;
    }
}

@keyframes overlay_spin {
    0%, 100% {
      transform: rotate(-30deg);
    }
    50% {
      transform: rotate(20deg);
    }
}

@keyframes overlay_spin_w_z {
    0%, 100% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(10deg) rotateX(-20deg) rotateY(10deg);
    }
}