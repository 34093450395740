
.left_nav {
    position: fixed;
    width: 300px;
    height: 100%;
    background-color: var(--trans_gray);
    border-right: 1px solid var(--light_gray);
    border-left: 1px solid var(--light_gray);
}

.extra_left {
    position: fixed;
    left: 0px;
    width: calc(50% - 600px);
    height: 100%;
    background-color: var(--trans_gray);
    opacity: 0.75;
}

.left_nav_item {
    position: relative;
    width: calc(100% + 20px);
    height: 50px;
    line-height: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0.5;
}

.left_nav_item_selected {
    /* background-color: var(--blue_gray);
    border-right: 2px solid var(--primary_blue);
    opacity: 1; */

    background-color: rgba(255, 255, 0, 0.1);
    border-right: 2px solid var(--primary_yellow);
    opacity: 1;
}

.left_nav_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    /* opacity: 0.5; */
}

.left_nav_icon_selected {
    opacity: 1;
}

.left_nav_label {
    position: absolute;
    top: 10px;
    left: 60px;
    line-height: 30px;
    font-family: "Unbounded", sans-serif;
    /* color: var(--light_gray); */
    color: white;
}

.left_nav_label_selected {
    color: white;
    font-weight: bold;
}

.left_nav_footer {
    position: absolute;
    left: 20px;
    width: calc(100% - 40px);
    bottom: 0px;
}

.bottom_nav {
    position: fixed;
    display: none;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: var(--trans_gray);
    background-color: black;
    border-top: 1px solid var(--light_gray);
    z-index: 1;
}

.bottom_nav_shader {
    position: fixed;
    display: none;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: var(--background_color);
    z-index: 1;
}

.buy_bottom_item {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    margin-top: -27px;
    width: 20%;
    height: 80px;
    background-color: var(--primary_yellow);
    border-radius: 20px;
    margin-bottom: 10px;
}

.buy_bottom_label {
    position: absolute;
    bottom: 5px;
    width: 100%;
    line-height: 30px;
    font-family: "Unbounded", sans-serif;
    color: black;
    text-align: center;
    font-size: 12px;
}

.buy_bottom_icon {
    position: absolute;
    top: 10px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
}



.bottom_nav_item {
    vertical-align: top;
    position: relative;
    display: inline-block;
    margin-top: -2px;
    width: 20%;
    height: 70px;
    opacity: 0.5;
}

.bottom_nav_item_selected {
    border-top: 2px solid var(--primary_blue);
    background-color: rgba(0, 200, 255, 0.1);
    opacity: 1;
}

.bottom_nav_item_icon {
    position: absolute;
    top: 10px;
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    /* opacity: 0.5; */
}

.bottom_nav_item_icon_selected {
    opacity: 1;
}

.bottom_nav_item_label {
    position: absolute;
    bottom: 0px;
    width: 100%;
    line-height: 30px;
    font-family: "Unbounded", sans-serif;
    /* color: var(--light_gray); */
    text-align: center;
    font-size: 12px;
}

.bottom_nav_item_label_selected {
    color: white;
}


.app_content {
    margin-left: 300px;
    width: calc(100% - 300px);
    /* min-height: 100vh; */
    /* border-right: 1px solid var(--light_gray); */
}

.fixed_right {
    right: calc(50% - 600px);
    top: 0px;
    position: fixed;
    width: 1px;
    height: 100%;
    background-color: var(--light_gray);
}

.full_width_button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: var(--primary_yellow);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    border-radius: 10px;
    text-align: center;
    color: black;
}

.extra_header {
    position: absolute;
    display: none;
    top: 0px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid var(--light_gray);
    background-color: black;
}

.extra_header_logo {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.extra_header_text_logo {
    position: absolute;
    left: 55px;
    top: 10px;
    line-height: 30px;
    height: 30px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    font-family: "Unbounded", sans-serif;
}

.install_app_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 110px;
    height: 28px;
    line-height: 28px;
    border: 1px solid var(--light_gray);
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
}

.extra_back_button {
    position: relative;
    background-color: var(--trans_gray);
    border-radius: 10px;
    display: none;
    vertical-align: middle;
    margin-right: 20px;
    width: 40px;
    height: 40px;
}

.extra_back_button_icon {
    position: absolute;
    top: 10px;
    left: 9px;
    width: 20px;
    height: 20px;
}

@media (max-width: 1200px) {
    .left_nav_item {
        width: calc(100% + 10px);
    }

    .fixed_right {
        right: 0px;
    }
}

@media (max-width: 850px) {

    .extra_back_button {
        display: inline-block;
    }

    .left_nav {
        display: none;
    }

    .fixed_right {
        display: none;
    }

    .extra_left {
        display: none;
    }

    .bottom_nav {
        display: block;
    }

    .bottom_nav_shader {
        display: block;
    }

    .extra_header {
        display: block;
    }

    .app_content {
        margin-left: 0px;
        width: 100%;
        padding-bottom: 70px;
        padding-top: 50px;
        border-right: none;
    }
    
    
}