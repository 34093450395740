
.background_shader {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0,0,0,0.75);
    backdrop-filter: blur(1px);
}

.empty_shade {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
    pointer-events: none;
}

.outer_modal_click {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.error_container {
    position: absolute;
    left: calc(50% - 250px);
    top: 50%;
    transform: translateY(-50%);
    width: 500px;
    background-color: var(--background_color);
    border-radius: 10px;
}

.error_text_content {
    margin-left: 20px;
    width: calc(100% - 40px);
    color: white;
    text-align: center;
}

.single_error_button {
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    text-align: center;
    color: white;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_blue);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.dual_error_button_0 {
    display: inline-block;
    margin-left: 20px;
    width: calc(50% - 30px);
    height: 50px;
    text-align: center;
    color: white;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_blue);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

.dual_error_button_1 {
    display: inline-block;
    margin-left: 20px;
    width: calc(50% - 30px);
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    border: 1px solid gray;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
}

@media (max-width: 520px) {
    .error_container {
        left: 10px;
        width: calc(100% - 20px);
    }

    .dual_error_button_0 {
        display: block;
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .dual_error_button_1 {
        display: block;
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
    }
}