

.more_button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 48px;
    height: 48px;
    border: 1px solid var(--light_gray);
    border-radius: 10px;
}

.scan_button {
    position: absolute;
    right: 60px;
    top: 0px;
    width: 50px;
    height: 50px;
    background-color: var(--primary_yellow);
    border-radius: 10px;
}

.history_button {
    position: absolute;
    right: 60px;
    top: 0px;
    width: 48px;
    height: 48px;
    border: 1px solid var(--light_gray);
    border-radius: 10px;
}

.profile_picture {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--trans_gray);
}

.profile_info_container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    width: calc(100% - 120px);
}

.profile_send_button_full {
    display: block;
    width: 100%;
    height: 50px;
    background-color: var(--primary_blue);
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
}

.profile_send_button {
    display: inline-block;
    width: calc(50% - 5px);
    height: 50px;
    background-color: var(--primary_blue);
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
}

.profile_receive_button {
    display: inline-block;
    width: calc(50% - 7px);
    height: 48px;
    border: 1px solid var(--light_gray);
    border-radius: 10px;
    text-align: center;
    line-height: 48px;
    margin-left: 10px;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
}

.profile_guide_button {
    position: absolute;
    right: 310px;
    top: 0px;
    width: 48px;
    height: 48px;
    border: 1px solid var(--light_gray);
    border-radius: 10px;
    text-align: center;
    line-height: 48px;
    margin-left: 10px;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
}


.inner_icon_abs {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 30px;
    height: 30px;
}

.desktop_transfer_container {
    position: absolute;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 300px;
}

.desktop_app_only {
    display: block;
}

.mobile_app_only {
    display: none;
}

.data_container_large {
    vertical-align: top;
    display: inline-block;
    position: relative;
    width: calc(50% - 7.5px);
    height: 100px;
    border: 1px solid var(--light_gray);
    /* background-color: var(--trans_gray); */
    border-radius: 10px;
    margin-right: 10px;
}

.data_container {
    vertical-align: top;
    display: inline-block;
    position: relative;
    width: calc(25% - 9.5px);
    height: 100px;
    border: 1px solid var(--light_gray);
    /* background-color: var(--trans_gray); */
    border-radius: 10px;
    margin-right: 10px;
}

.data_container_last {
    margin-right: 0px;
}

.data_amount {
    position: absolute;
    top: 15px;
    width: 100%;
    line-height: 40px;
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.data_description {
    position: absolute;
    bottom: 15px;
    width: 100%;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
}

.standard_chart_container {
    position: relative;
    min-height: 500px;
    width: 100%;
}

.standard_chart {
    width: 100%;
    height: 100%;
}

.chart_time_selector_container {
    position: absolute;
    vertical-align: top;
    right: 0px;
    top: 0px;
    width: 185px;
    height: 40px;
    background-color: var(--trans_gray);
    border-radius: 10px;
}

.chart_time_item {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -12px;
    width: 40px;
    height: 30px;
    border-radius: 7px;
    text-align: center;
    line-height: 30px;
    background-color: var(--trans_gray);
    font-size: 14px;
    color: gray;
}

.chart_time_item_selected {
    color: white;
    background-color: var(--primary_blue);
    font-weight: bold;
}

.data_container_second.special {
    margin-right: 0px;
    margin-bottom: 10px;
}

.data_container.special {
    width: calc(50% - 7px);
}

.large_claim_countdown_button {
    width: 350px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    border: 1px solid gray;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}

.large_claim_countdown_button.available {
    width: 250px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_yellow);
    border: none;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
    color: black;
}

@media (max-width: 850px) {

    .large_claim_countdown_button {
        width: 100%;
    }

    .large_claim_countdown_button.available {
        width: 100%;
    }

    .data_container_large {
        width: calc(100% - 2px);
    }

    .mobile_app_only {
        display: block;
    }

    .desktop_app_only {
        display: none;
    }

    .data_container_second {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .data_container {
        width: calc(50% - 7px);
    }

    .data_description {
        font-size: 12px;
    }
}
