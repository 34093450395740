

.canvas_container {
    position: relative;
    width: 400px;
    margin-left: calc(50% - 200px);
}

.canvas_logo {
    position: absolute;
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
}

.extra_label_outer {
    position: relative;
    width: 100%;
}

.custom_label_container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    margin-top: 20px;
}

.label_color_container {
    display: inline-block;
    width: 50px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    text-align: left;
    opacity: 0.5;
}

.canvas_opacity {
    opacity: 0.5;
}

.text_left_align {
    display: inline;
    text-align: left;
    width: auto;
}

@media (max-width: 450px) {
    .canvas_container {
        width: 100%;
        margin-left: 0px;
    }
}