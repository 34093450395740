

.sales_widget_container {
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin-top: 40px;
    width: 450px;
    /* height: 500px; */
    border-radius: 10px;
    /* background-color: black; */
    padding: 20px;
    color: white;
    background-color: var(--trans_gray_dark);
    backdrop-filter: blur(3px);

    /* animation: pulse_yellow_glow 2s infinite ease-in-out; */
}

.central_text_indicator {
    text-align: center;
}


.countdown_container {
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px;
    width: calc(100% - 40px);
    background-color: var(--dark_gray);
    background-color: var(--trans_gray);
}

.countdown_inner_container {
    margin-top: 10px;
}

.countdown_item {
    display: inline-block;
    margin-left: 10px;
    width: calc(25% - 12.5px);
    text-align: center;
    color: var(--primary_yellow);
    height: 50px;
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;
}

.countdown_item_indicator {
    display: inline-block;
    margin-left: 10px;
    width: calc(25% - 12.5px);
    text-align: center;
    font-size: 12px;
}

.outer_progress_bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: var(--dark_gray);
    background-color: var(--trans_gray);
    overflow: hidden;
    border-radius: 10px;
}


.inner_progress_bar {
    position: absolute;
    width: 30%; /* Set to your desired initial load percentage */
    height: 100%; /* Full height of the outer bar */
    background: repeating-linear-gradient(
        -45deg, /* Angle of the stripes */
        var(--primary_yellow), /* Color of the stripes */
        var(--primary_yellow) 5px, /* Width of each stripe */
        var(--dark_gray) 5px, /* Alternate color to create a visible stripe */
        var(--dark_gray) 10px /* Width between stripes */
    );
    background-size: 28px 28px;
    animation: moveStripes 1s linear infinite; /* Animation for moving the stripes */
    transition: width 0.5s ease-in-out;
}

/* Keyframes for moving the stripes left to right */
@keyframes moveStripes {
    from { background-position: 0px; }
    to { background-position: 28px; }
}

.payment_method_container {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    overflow: scroll;
    scrollbar-width: none;
}

.payment_method_container::-webkit-scrollbar {
    display: none;
}

.payment_method_inner {
    width: 907px;
    height: 50px;
}

.payment_method_item {
    display: inline-block;
    padding: 5px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--dark_gray);
    background-color: var(--trans_gray);
    font-size: 14px;
    border-radius: 10px;
    margin-right: 10px;
}

.non_selectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.payment_method_item.selected {
    height: 38px;
    padding-left: 9px;
    padding-right: 9px;
    border: 1px solid var(--primary_yellow);
}

.payment_method_item:last-child {
    margin-right: 0px;
}

.payment_method_label {
    display: inline-block;
    margin-top: 5px;
    vertical-align: top;
    margin-left: 10px;
    line-height: 30px;
}

.payment_method_icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    pointer-events: none;
}

.payment_input_container {
    position: relative;
    width: calc(100% - 4px);
    height: 58px;
    border-radius: 10px;
    border: 2px solid var(--dark_gray);
    border: 2px solid var(--trans_gray);
}

.payment_half_container {
    display: inline-block;
    width: calc(50% - 10px);
}

.payment_half_container.left {
    margin-right: 20px;
}

.payment_input_container_half {
    position: relative;
    display: inline-block;
    width: calc(50% - 14px);
    height: 58px;
    border-radius: 10px;
    border: 2px solid var(--trans_gray);
}

.inner_payment_rel {
    position: absolute;
    left: 60px;
    top: 14px;
    height: 30px;
    width: calc(100% - 72px);
}

.payment_input_currency_selector {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 75px;
    height: 50px;
    background-color: var(--dark_gray);
    background-color: var(--trans_gray);
    border-radius: 6px;
}

.payment_input_selector_icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.payment_input_selector_extra_icon {
    position: absolute;
    left: 52px;
    top: 17.5px;
    width: 15px;
    height: 15px;
}

.payment_button {
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: var(--primary_yellow);
    font-weight: bold;
    color: var(--dark_black);
    border-radius: 10px;
}

.payment_button_dull {
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: var(--trans_gray);
    font-weight: bold;
    /* color: var(--dark_black); */
    color: white;
    border-radius: 10px;
}

.small_button_pop {
    display: inline-block;
    width: calc(50% - 5px);
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--primary_yellow);
    font-weight: bold;
    color: var(--dark_black);
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    pointer-events: all;
}

.small_button_pop.dull {
    color: white;
    background-color: var(--trans_gray);
    margin-right: 0px;
}


.edit_input_button {
    position: absolute;
    top: 4px;
    right: 53px;
    width: 45px;
    height: 50px;
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
    line-height: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.edit_input_button_icon {
    position: absolute;
    left: 7.5px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.stepper_up_input_button {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 45px;
    height: 23px;
    background-color: var(--trans_gray);
    border-top-right-radius: 6px;
    text-align: center;
    line-height: 25px;
    overflow: hidden;
}

.stepper_down_input_button {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 45px;
    height: 23px;
    background-color: var(--trans_gray);
    border-bottom-right-radius: 6px;
    text-align: center;
    line-height: 23px;
    overflow: hidden;
}

.up_input_button {
    position: absolute;
    top: 9px;
    right: 58px;
    width: 40px;
    height: 40px;
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
    line-height: 40px;
}

.down_input_button {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 40px;
    height: 40px;
    background-color: var(--trans_gray);
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
    line-height: 40px;
}


.value_input {
    all: unset;
    position: absolute;
    top: 4px;
    left: 90px;
    height: 50px;
    width: calc(100% - 255px);
    background-color: transparent;
    border: none;
    font-size: 24px;
    text-align: right;
}

.value_input_max {
    all: unset;
    position: absolute;
    top: 4px;
    left: 90px;
    height: 50px;
    width: calc(100% - 175px);
    background-color: transparent;
    border: none;
    font-size: 24px;
    text-align: right;
    pointer-events: none;
}

.currency_label {
    position: absolute;
    top: 4px;
    right: 108px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    color: gray;
    font-size: 18px;
}

.currency_label.extra {
    right: 108px;
    width: 68px;
}


.value_input.extra {
    width: calc(100% - 200px);
}

.currency_label_max {
    position: absolute;
    top: 4px;
    right: 10px;
    line-height: 50px;
    width: 70px;
    height: 50px;
    color: gray;
    font-size: 18px;
    text-align: right;
}

.discount_table_container {
    overflow-x: scroll;
    margin-left: 20px;
    margin-right: 20px;
}

.discount_table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.discount_table th,
.discount_table td {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    text-align: center;
}


.discount_table th {
    background-color: var(--light_gray);
    color: white;
    text-align: center;
}

.terms_container {

}

.terms_button {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: var(--trans_gray);
    border: 2px solid var(--trans_gray);
    text-align: center;
    line-height: 36px;
    font-size: 30px;
}


.terms_button.selected {
    background-color: var(--primary_blue);
}

.terms_icon_ticked {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 30px;
    height: 30px;
}

.terms_text {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 50px);
    font-size: 12px;
}

@media (max-width: 850px) {
    .sales_widget_container {
        display: block;
        margin-right: calc(50% - 290px);
        margin-left: auto;
        width: 550px;
    }
}

@media (max-width: 620px) {
    .sales_widget_container {
        display: block;
        margin-right: 0px;
        margin-left: auto;
        width: calc(100% - 40px);
    }
}

@media (max-width: 500px) {
    .countdown_container {
        padding: 10px;
        width: calc(100% - 20px);
    }

    .countdown_item {
        font-size: 40px;
    }
}