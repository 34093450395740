

:root {


    --dark_black: black;
    --background_color: #0e0f15;
    --background_color: #061420;
    --dark_gray: #282a30;
    --blue_gray: #102a43;
    --light_gray: gray;
    --primary_blue: #00b5fa;
    --primary_yellow: #ffec71;


    --primary_blue: #00d9ff;
    --primary_yellow: #ffff00;
    --extra_orange: #ffb700;


    --primary_blue: #00c8ff;

    --trans_gray: rgba(80, 80, 80, 0.25);
    --trans_gray_med: rgba(6, 20, 32, 0.5);
    --trans_gray_dark: rgba(6, 20, 32, 0.75);



    
    --blue_text: #00b5fa;
    --pink_text: #ff66f9;
    --green_text: #00bc97;
    --purple_text: #9458f8;
    --gray_text: #847c5f;
    --orange_text: #ff4220;

    --blue_background: #f1f5fb;
    --pink_background: #fdebfc;
    --green_background: #effaf8;
    --purple_background: #f7f2fe;
    --gray_background: #f7f7f6;
    --orange_background: #fcf6f3;


}