

.featured_scroll_container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap; 
    scrollbar-width: none;
}

.featured_scroll_container::-webkit-scrollbar {
    display: none;
}

.featured_image {
    opacity: 0.5;
    height: 30px;
    margin-right: 50px;
}

@media (max-width: 520px) {
    .featured_scroll_container {
        margin-left: -10px;
        width: calc(100% + 20px);
    }
}