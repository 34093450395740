
.faq_items_container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(70% - 20px);
    margin-right: 20px;
}

.faq_image_container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(30% - 2px);
    float: right;
    border: 1px solid var(--light_gray);
    background-color: var(--trans_gray);
    min-height: 200px;
}


@media (max-width: 850px) {
    .faq_items_container {
        display: block;
        width: 100%;
        margin-right: 0px;
    }

    .faq_image_container {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        float: none;
    }
}

.faq_image {
    position: relative;
    display: block;
    width: 100%;
    opacity: 0.9;
}

.faq_pulse {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    animation: token_pulse 3s linear infinite;
}

.faq_item {
    position: relative;
    width: calc(100% - 2px);
    /* height: 70px; */
    border-top: 1px solid var(--light_gray);
    border-left: 1px solid var(--light_gray);
    border-right: 1px solid var(--light_gray);
}

.faq_item_last {
    border-bottom: 1px solid var(--light_gray);
}

.plus_icon_container {
    position: absolute;
    left: 25px;
    top: 25px;
    height: 20px;
    width: 20px;
}

.plus_horizontal_bar {
    position: absolute;
    top: calc(50% - 1px);
    height: 2px;
    width: 100%;
    background-color: white;
}

.plus_vertical_bar {
    position: absolute;
    top: 0px;
    left: calc(50% - 1px);
    height: 100%;
    width: 2px;
    background-color: white;
    transition: transform 0.3s ease;
}

.plus_vertical_bar.open {
    transform: rotate(90deg);
}

.faq_title {
    /* position: absolute; */
    margin-left: 70px;
    margin-top: 21px;
    padding-bottom: 21px;
    width: calc(100% - 90px);
    /* line-height: 50px; */
}

.faq_title.open {
    color: var(--primary_blue);
}

.faq_description {
    display: none;
    margin-top: 0px;
    margin-left: 20px;
    width: calc(100% - 40px);
    /* color: gray; */
    padding-bottom: 21px;
}

.faq_description.open {
    display: block;
}