
.smallest_text {
    font-size: 12px;
}

.small_text {
    font-size: 14px;
}

.normal_text {
    font-size: 18px;
}

.large_text {
    font-size: 24px;
}

.grayed_text {
    color: var(--light_gray)
}

.important_text {
    color: var(--primary_blue);
}

.title_font_family {
    font-family: "Unbounded", sans-serif;
    font-weight: bold;
}

.special_a {
    color: white;
}

.warning_text {
    color: var(--orange_text);
}

.green_text {
    color: var(--green_text);
}

.yellow_text {
    color: var(--primary_yellow);
}

@media (max-width: 850px) {
    .center_mobile {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 520px) {
    .large_text {
        font-size: 20px;
    }
}