

.top_content_container {
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin-right: 20px;
    margin-top: 180px;
    width: calc(100% - 510px);
}

.usp_container {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    overflow: hidden;
    position: relative;
    height: 50px;
    background-color: rgba(80, 80, 80, 0.25);
    backdrop-filter: blur(20px);
    font-family: "Unbounded", sans-serif;
    line-height: 50px;
}

.usp_container {
    width: 460px;
}

.changing_container {
    top: 0px;
    position: absolute;
    height: 100%;
    margin-left: 115px;
    color: var(--primary_blue);
    /* background-color: red; */
}


.changing_text_0 {
    position: relative;
    /* animation-delay: 2s; */
    animation: slide_up 1s linear forwards;
    font-family: "Unbounded", sans-serif;
    /* background-color: green; */
}

.changing_text_1 {
    position: relative;
    /* animation-delay: 2s; */
    animation: slide_in 1s linear forwards;
    font-family: "Unbounded", sans-serif;
    /* background-color: yellow; */
}

.radial_glow_top {
    position: absolute;
    top: -25px;
    left: 0px;
    background: radial-gradient(circle, rgba(0,181,250,0.2), transparent 70%);
    border-radius: 50%;
    width: 400px;
    height: 250px;
}

@keyframes slide_up {
    0% {
        top: 100%;
        /* transform: translateY(0); */
    }
    100% {
        top: 0px;
        /* transform: translateY(-50%); */
    }
}

@keyframes slide_in {
    0% {
        top: 0px;
        /* transform: translateY(100%); */
    }
    100% {
        top: -100%;
        /* transform: translateY(0); */
    }
}

@media (max-width: 850px) {
    .top_content_container {
        display: block;
        margin-right: 0px;
        width: 100%;
        padding-top: 200px;
        margin-top: -50px;
    }

    .radial_glow_top {
        top: 230px;
        width: 300px;
        height: 120px;
        left: 50%;
        transform: translateX(-50%);
    }

    .usp_container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .add_indent {
        margin-left: calc(50% - 250px);
        width: 550px;
    }

}

@media (max-width: 620px) {
    .usp_container {
        position: relative;
        font-size: 28px;
        height: 32px;
        line-height: 32px;
        left: 50%;
        transform: translateX(-50%);
    }

    .top_usp_container {
        width: 315px;
    }

    .changing_container {
        margin-left: 85px;
    }

    .add_indent {
        margin-left: 0px;
        width: 100%;
    }

    
}

@media (max-width: 400px) {
    .usp_container {
        font-size: 24px;
    }

    .top_usp_container {
        width: calc(100% - 40px);
    }

    .changing_container {
        margin-left: 75px;
    }
}