

.inline_button_left {
    display: inline-block;
    margin-right: 20px;
    width: 160px;
    height: 50px;
    text-align: center;
    color: white;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_blue);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}


.inline_button_left.yellow {
    color: black;
    background-color: var(--primary_yellow);
}

.inline_button_right {
    display: inline-block;
    width: 158px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    border: 1px solid gray;
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}

.inline_badges {
    display: inline-block;
    margin-right: 20px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid white;
    filter: drop-shadow(0px 0px 2px var(--primary_yellow));
}

.inline_badges.right {
    margin-right: 0px;
    /* filter: drop-shadow(0px 0px 2px var(--primary_blue)); */
}

.inline_small_button {
    display: inline-block;
    margin-top: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    height: 30px;
    text-align: center;
    color: black;
    line-height: 30px;
    border-radius: 10px;
    background-color: var(--primary_yellow);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}

.large_yellow_button_single {
    margin-left: calc(50% - 125px);
    width: 250px;
    height: 50px;
    text-align: center;
    color: black;
    line-height: 50px;
    border-radius: 10px;
    background-color: var(--primary_yellow);
    font-weight: bold;
    font-family: "Unbounded", sans-serif;
    overflow: hidden;
}

.extra_indicator_box {
    padding: 10px;
    background-color: rgba(255, 255, 0, 0.1);
    border-radius: 10px;
    border: 1px solid var(--primary_yellow);
}

.small_img_indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
    display: none;
}

.temp_unselectable {
    pointer-events: none;
    opacity: 0.2;
}

.extra_email_icon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
}

@media (max-width: 850px) {
    /* .large_yellow_button_single {
        margin-left: 10px;
    } */

    .inline_badges {
        margin-left: calc(50% - 142px);
        height: auto;
        width: 130px;
    }

    .inline_badges.right {
        margin-left: 0px;
    }
}

@media (max-width: 520px) {
    .inline_button_left {
        width: calc(50% - 11px);
    }

    .inline_button_right {
        width: calc(50% - 11px);
    }

    
}

@media (max-width: 420px) {
    .inline_button_left {
        display: block;
        width: 100%;
    }

    .inline_button_right {
        display: block;
        margin-top: 20px;
        width: 100%;
    }
}