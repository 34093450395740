
.loading_container {
    position: relative;
    width: 280px;
    left: calc(50% - 140px);
    z-index: 2;
    background-color: black;
    border-radius: 10px;
    border: 1px solid var(--light_gray);
}

.loading_text {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.loading_wheel {
    margin-top: 20px;
    margin-left: calc(50% - 25px);
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,var(--primary_yellow) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,var(--primary_yellow));
      
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);

    animation: spin_wheel 1s infinite linear;
  }

@keyframes spin_wheel { 
    100%{transform: rotate(1turn)}
}