.big_big_spacer {
    width: 100%;
    height: 70px;
}

@media (max-width: 520px) {
    .big_big_spacer {
        height: 130px;
    }
}

.page_container_no {
    position: relative;
    margin-left: calc(50% - 600px);
    width: 1200px;
}

.hide_overflow_container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.page_container {
    position: relative;
    margin-left: calc(50% - 600px);
    width: 1200px;
    /* overflow-x: hidden; */
}

.safe_inner {
    position: relative;
    margin-left: 20px;
    width: calc(100% - 40px);
}

.full_width_image {
    display: block;
    width: 100%;
}

.small_spacer {
    width: 100%;
    height: 10px;
}

.medium_spacer {
    width: 100%;
    height: 20px;
}

.large_spacer {
    width: 100%;
    height: 40px;
}

.hero_cat_image {
    /* display: none; */
    position: absolute;
    object-fit: contain;
    top: -120px;
    left: -12%;
    width: 70%;
    z-index: 0;
    opacity: 1;
    -webkit-mask-image: linear-gradient(to top, transparent, 25%, var(--background_color) 75%);
    mask-image: linear-gradient(to top, transparent, 25%, var(--background_color) 75%);
}

@media (max-width: 1200px) {
    .page_container {
        margin-left: 0px;
        width: 100%;
    }

    .page_container_no {
        margin-left: 0px;
        width: 100%;
    }

    .safe_inner {
        margin-left: 10px;
        width: calc(100% - 20px);
    }
}

@media (max-width: 850px) {
    .hero_cat_image {
        top: -25px;
        left: -10%;
        width: 100%;
        /* height: 150vw; */
        -webkit-mask-image: linear-gradient(to top, transparent, 25%, var(--background_color) 40%);
        mask-image: linear-gradient(to top, transparent, 25%, var(--background_color) 40%);
    }
}


.hero_cat_flowing_image {
    /* display: block;
    opacity: 0.8;
    -webkit-mask-image: linear-gradient(to right, var(--trans_gray_med), var(--primary_yellow), var(--trans_gray_med));
    mask-image: linear-gradient(to right, var(--trans_gray_med), var(--primary_yellow), var(--trans_gray_med));
    -webkit-mask-size: 10% 200%;
    mask-size: 10% 200%;
    animation: sliding_mask 3s linear infinite; */
    animation: token_pulse 3s linear infinite;
}

.hero_cat_overlay_image {
    display: block;
    animation: pulstate 3s infinite, sliding_mask 3s linear infinite;
}

.tg_support {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 104, 236, 1);
    border-radius: 60px;
    z-index: 100000;
    transition: transform 0.5s ease-out;
    animation: tg_slide 0.5s ease-out forwards;
}

.tg_support_icon {
    position: absolute;
    top: 10px;
    left: 8px;
    width: 40px;
    height: 40px;
}

@keyframes tg_slide {
    0% {
        bottom: -80px;
    }
    100% {
        bottom: 20px;
    }
}

@keyframes sliding_mask {
    100% {
        -webkit-mask-position: 100% center;
        mask-position: 100% center;
    }
    0% {
        -webkit-mask-position: 0 center;
        mask-position: 0 center;
    }
}

@keyframes pulstate {
    0%, 100% {
        opacity: 0;
        filter: brightness(0);
    }
    50% {
        opacity: 0.5;
        filter: brightness(1.2);
    }
}
