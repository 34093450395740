

.trapeze_container {
    position: relative;
    width: 100%;
    background-color: var(--dark_black);
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 50px 100%, 0 calc(100% - 50px));
}

.trapeze_container_top {
    position: relative;
    width: 100%;
    background-color: var(--dark_black);
    clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 0 100%);

    background-color: rgba(80, 80, 80, 0.25);
    backdrop-filter: blur(20px);
}

.trapeze_container_bottom {
    position: relative;
    width: 100%;
    background-color: var(--dark_black);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50px 100%, 0 calc(100% - 50px));

    background-color: rgba(80, 80, 80, 0.25);
    backdrop-filter: blur(20px);
}

.trapeze_container_bottom::after {
    content: "";
    display: table;
    clear: both;
}

.radial_glow_extra_top_blue {
    position: absolute;
    top: 25px;
    left: 0px;
    background: radial-gradient(circle, rgba(0,181,250,0.2), transparent 70%);
    border-radius: 50%;
    width: 250px;
    height: 250px;
}

.radial_glow_extra_top_blue.right {
    left: 50%;
}

.radial_glow_extra_bottom_blue {
    position: absolute;
    bottom: 5px;
    right: 0px;
    background: radial-gradient(circle, rgba(0,181,250,0.2), transparent 70%);
    border-radius: 50%;
    width: 250px;
    height: 250px;
}



.live_sales_description_container {
    display: inline-block;
    padding: 40px;
    width: calc(50% - 80px);
    vertical-align: middle;
}

.live_sales_container {
    position: relative;
    display: inline-block;
    padding: 40px;
    width: calc(50% - 80px);
    vertical-align: middle;
}

.live_sales_inner_container {
    position: relative;
    width: 100%;
    height: 410px;
    overflow: scroll;
    scrollbar-width: none;
}

.live_sales_inner_container::-webkit-scrollbar {
    display: none;
}

.live_sales_item {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: var(--background_color);
    /* background-color: var(--dark_black); */
    animation: fade_in_fast 0.5s ease forwards;
}

@keyframes fade_in_fast {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.live_sales_icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.live_sales_amount_label {
    position: absolute;
    left: 60px;
    top: 10px;
    line-height: 30px;
    height: 30px;
}

.quizz_label {
    position: relative;
    /* position: absolute; */
    left: 60px;
    top: 10px;
    line-height: 30px;
    /* background-color: red; */
    width: calc(100% - 70px);
    padding-bottom: 20px;
    /* height: 30px; */
}

.live_sales_time_label {
    position: absolute;
    right: 20px;
    top: 10px;
    line-height: 30px;
    height: 30px;
    text-align: right;
    font-size: 14px;
}

.middle_line {
    position: absolute;
    top: 0px;
    left: calc(50% - 0.5px);
    width: 1px;
    height: 100%;
    background-color: var(--background_color);
}

.top_box_decor {
    position: absolute;
    top: 0px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: var(--background_color);
}

.bottom_box_decor {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: var(--background_color);
}

@media (max-width: 850px) {
    .live_sales_description_container {
        padding: 40px 20px;
        display: block;
        width: calc(100% - 40px);
    }

    .live_sales_container {
        padding: 40px 20px;
        display: block;
        margin-top: -40px;
        width: calc(100% - 40px);
    }

    .live_sales_inner_container {
        height: 290px;
    }

    .middle_line {
        display: none;
    }

    .top_box_decor {
        display: none;
    }

    .bottom_box_decor {
        display: none;
    }
/* 
    .trapeze_container_bottom {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 25px 100%, 0 calc(100% - 25px));
    } */

}
