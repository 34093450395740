

.full_outter_box {
    position: relative;
    width: 100%;
    border: 1px solid var(--light_gray);
}

.develop_lhs {
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: calc(50% - 1px);
    height: 100%;
    
}

.develop_rhs {
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: 50%;
    height: 100%;
    border-left: 1px solid var(--light_gray);
}

.develop_main_title_box {
    padding: 40px;
    width: calc(100% - 80px);
    border-bottom: 1px solid var(--light_gray);
}

.develop_right_text_box {
    padding: 40px;
    width: calc(100% - 80px);
    border-bottom: 1px solid var(--light_gray);
    background-color: var(--trans_gray);
}

.develop_bottom_right {
    padding: 40px;
    width: calc(100% - 80px);
}

.develop_code_example {
    position: relative;
    width: 100%;
    height: 400px;
    /* animation: pan 5s infinite ease-in-out; */
}

.develop_code_inner {
    position: relative;
    margin-left: 20px;
    top: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    
}


@keyframes pan {
    0%, 100% {
      transform: rotateX(0deg) rotateY(0deg) translateZ(0);
    }
    50% {
      transform: rotateX(20deg) rotateY(-15deg) translateZ(-20px);
    }
  }

.develop_code_itself {
    position: absolute;
    top: 15px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    font-family: "Roboto Mono", monospace;
    white-space: nowrap;
    font-size: 12px;
    overflow: scroll;
    scrollbar-width: none;
}

.develop_code_itself::-webkit-scrollbar {
    display: none;
}

.dev_red {
    color: #c1371e;
    font-family: "Roboto Mono", monospace;
}

.dev_yellow {
    color: #d9d902;
    font-family: "Roboto Mono", monospace;
}

.dev_blue_dark {
    color: var(--primary_blue);
    font-family: "Roboto Mono", monospace;
}

.dev_blue {
    color: #aad7fb;
    font-family: "Roboto Mono", monospace;
}

.dev_green {
    color: var(--green_text);
    font-family: "Roboto Mono", monospace;
}

.dev_indent {
    display: inline-block;
    margin-left: 20px;
    font-family: "Roboto Mono", monospace;
}

@media (max-width: 850px) {
    .develop_lhs {
        display: block;
        width: 100%;
    }

    .develop_rhs {
        display: block;
        width: 100%;
        border-left: none;
        border-top: 1px solid var(--light_gray);
    }
}